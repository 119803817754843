<template>
  <div>
    <div class="generateReportForInsuranceOuterContainer">

      <div id="my-node2" class="reportForInsuranceContainer" v-if="volunteerData && statisticsData">
        <div class="unitInfoContainer">
          <div class="unitInfoLabel">POTRDILO O OPRAVLJANJU PROSTOVOLJSKEGA DELA</div>
        </div>

        <div class="volunteersContainer">
          <p>{{ volunteerData.gender === 'female' ? 'Prostovoljka' : 'Prostovoljec' }}
            <b><u>{{ volunteerData.first_name }} {{ volunteerData.last_name }}</u></b>,<br/><br/></p>
          <p>{{ volunteerData.gender === 'female' ? 'rojena' : 'rojen' }} dne
            <b><u>{{ moment(volunteerData.birthday).format('DD. MM. YYYY') }}</u></b>,<br/><br/></p>
          <p>{{ volunteerData.gender === 'female' ? 'stanujoča' : 'stanujoč' }} v/na
            <b><u>{{ volunteerData.addresses.quick_search }}</u></b>,<br/><br/></p>
          <p>ima z organizacijo <b><u>Zavod Sopotniki, zavod za medgeneracijsko solidarnost, Kraška ulica 2, 6210
            Sežana</u></b><br/><br/></p>
          <p>sklenjen <b><u>Dogovor o opravljanju prostovoljskega dela
            ({{ moment(volunteerData.agreementValidityDate).format('DD. MM. YYYY') }})</u></b> za obdobje
            <b><u>{{ moment(volunteerData.agreementValidityDate).subtract(1, 'years').format('DD. MM. YYYY') }} -
              {{ moment(volunteerData.agreementValidityDate).format('DD. MM. YYYY') }}</u></b>.<br/><br/></p>

          <p>S tem potrdilom se dokazuje opravljanje prostovoljskega dela in je podlaga za upravičenost do dodatka za
            delovno aktivnost pri upravičencih do denarne socialne pomoči na podlagi 26. člena Zakona o socialno
            varstvenih prejemkih (Uradni list RS, št. 61/10, 40/11, 14/13, 99/13, 90/15, 88/16, 31/18 in 73/18).</p>
          <br/><br/>
          <p><b>Potrdilo je prostovoljec dolžan MESEČNO</b> posredovati pristojnemu centru za socialno delo, osebno, po
            e-pošti ali po pošti in sicer <b>do 8. dne v mesecu za pretekli mesec</b>.</p>
          <br/><br/>
          <p style="border: 1px solid #000; padding: 3px;"><b>OPOZORILO:</b> Če dokazilo o opravljenem prostovoljskem
            delu za posamezni mesec oziroma obdobje priznanega dodatka za delovno aktivnost ni predloženo, oseba za
            mesec, ko ni izkazano opravljanje prostovoljnega dela, ni upravičena do dodatka za delovno aktivnost.</p>
          <br/><br/>
          <p>Za več informacij se obrnite na center za socialno delo, ki je priznal pravico do denarne socialne
            pomoči.</p>

        </div>
      </div>

      <div id="my-node2" class="reportForInsuranceContainer" v-if="volunteerData && statisticsData">

        <div class="unitInfoContainer">
          <div class="unitInfoLabel">LISTA PRISOTNOSTI PROSTOVOLJCA in EVIDENCA UR
            za mesec {{ moment(startDate).format('MMMM') }}, leto {{ moment(startDate).format('YYYY') }}
          </div>
        </div>

        <div class="volunteersContainer">
          <br/><br/>

          <table class="table" style="width: 100%; text-align: left; border: 1px solid #000; border-collapse: collapse">
            <thead>
            <tr>
              <th class="p-1" style="border: 1px solid #000;">Mesec</th>
              <th class="p-1" style="width: 130px; border: 1px solid #000;">Število ur</th>
              <th style="width: 300px; border: 1px solid #000;" class="p-1">Opis dela</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="p-1" style="border: 1px solid #000;"><b><u>{{ moment(startDate).format('MMMM') }}
                {{ moment(startDate).format('YYYY') }}</u></b>
              </td>
              <td class="p-1" style="border: 1px solid #000;"><b><u>{{ calculateTotalHours(statisticsData[0].data) }}</u></b></td>
              <td class="p-1" style="width: 300px; border: 1px solid #000;">
                Opravljanje prevozov z namenskim vozilom v okviru izvajanja storitve brezplačnih prevozov za starejše
                Sopotniki.
              </td>
            </tr>
            </tbody>
          </table>
          <br/><br/>
          <p v-if="unitManagerData">Ime in priimek mentorja (tiskano): <b><u>{{unitManagerData.first_name.toUpperCase()}} {{unitManagerData.last_name.toUpperCase()}}</u></b><br/><br/></p>
          <p>Ime in priimek kontaktne osebe (tiskano): <b><u>MARKO ZEVNIK</u></b><br/><br/></p>
          <p>Telefon kontaktne osebe: <b><u>040 162 875</u></b><br/><br/><br/></p>

          <em>
          <p>Izjava organizacije:<br/><br/></p>
          <p>Pod materialno in kazensko odgovornostjo s podpisom zagotavljamo, da so navedeni podatki
            resnični.<br/><br/><br/></p>
          </em>

          <p>Ime in priimek odgovorne osebe (tiskano)<br/><br/></p>
          <p><b><u>MARKO ZEVNIK</u></b><br/><br/></p>
          <p>Žig in podpis<br/><br/></p>
          <img :src="require('@/assets/images/content/business_letters/marko.png')" class="podpis">
          <img :src="require('@/assets/images/content/business_letters/scan_zig.png')" class="zig">
          <br/><br/>
          <p>V/na: <b><u>Sežana</u></b>, dne: <b><u>{{ moment().format('DD. MM. YYYY') }}</u></b><br/><br/></p>
        </div>
      </div>


    </div>

  </div>

</template>

<script>
import Vue from "vue";
import VueQrcode from "vue-qrcode";

export default {
  name: 'StatisticsOnVolunteersSocialWorkCentre',

  components: {
    VueQrcode
  },
  data() {
    return {
      volunteerId: null,
      volunteerData: null,
      statisticsData: null,
      startDate: null,
      endDate: null,
      unitId: null,
      unitManagerId: null,
      unitManagerData: null,
    }
  },

  computed: {},

  async mounted() {
    const _this = this;


    if (this.$route.query.jwt) {
      Vue.prototype.$http.interceptors.request.use(
        config => {
          const token = this.$route.query.jwt;
          if (token) {
            if (config.url.includes('https://api.sopotniki.org/')) {
              config.headers.common["Authorization"] = 'Bearer ' + token;
            } else {
              config.headers.common["Authorization"] = token;
            }
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );

    }

    //_this.donorData = _this.$route.params.donorData;
    _this.volunteerId = Number(_this.$route.query.volunteerId) || _this.$route.params.volunteerId;
    _this.startDate = Number(_this.$route.query.startDate) || _this.$route.params.startDate;
    _this.endDate = Number(_this.$route.query.endDate) || _this.$route.params.endDate;
    _this.unitId = Number(_this.$route.query.unitId) || _this.$route.params.unitId;
    await _this.getDailyReports();
    await _this.getVolunteerData();
    _this.unitManagerId = _this.statisticsData[0].data[0].unit.volunteersId;
    console.log("this.statisticsData", _this.statisticsData[0].data[0].unit.volunteersId);
    await _this.getUnitManagerData();
  },
  methods: {
    async getDailyReports() {
      const _this = this;
      _this.$vs.loading();

      const unit = _this.unitId;
      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${unit}&startDate=${_this.moment(_this.startDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.endDate).endOf('month').valueOf()}`;

      await Vue.prototype.$http.get(url)
        .then((res) => {
          const dailyReportsData = _.filter(res.data.data, ['volunteer.id', _this.volunteerId]);

          _this.statisticsData = _.chain(dailyReportsData)
            .groupBy('volunteer.id')
            .map((value, key) => {
              return {volunteer: key, statisticsData: value[0].volunteer, data: value}
            })
            .value();

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
        });
    },
    async getVolunteerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.volunteerId)
        .then((res) => {
          _this.volunteerData = res.data.data;
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    async getUnitManagerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.unitManagerId)
        .then((res) => {
          _this.unitManagerData = res.data.data;
          _this.$vs.loading.close();
          console.log("unitManagerData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    calculateTotalHours(data) {
      console.log("data ---> ", data);
      let totalMinutes = null;
      _.each(data, function (val) {
        totalMinutes += val.volunteerHoursTotal ? (Number(val.volunteerHoursTotal.split(':')[0]) * 60) : 0;
        totalMinutes += val.volunteerHoursTotal ? Number(val.volunteerHoursTotal.split(':')[1]) : 0;
      });
      console.log("totalMinutes", totalMinutes);
      return this.convertMinsToHrsMins(totalMinutes);
    },

    convertMinsToHrsMins(minutes) {
      let h = Math.floor(minutes / 60);
      let m = minutes % 60;
      h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      return h + ':' + m;
    },

  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}

@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateReportForInsuranceOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .reportForInsuranceContainer {
    position: relative;
    overflow: hidden;
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 18% 20% 15% 7% !important;
    display: block !important;
    color: black !important;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 0px;
    }

    .reportDateContainer {
      position: absolute;
      left: 387px;
      top: 130px;

      .month {
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
        display: inline-block;
      }

      .yearLabel {
        font-size: 18px;
        padding: 0px 20px;
        color: #000000;
        text-transform: uppercase;
        display: inline-block;
      }

      .year {
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
        display: inline-block;
      }

    }

    .unitInfoContainer {
      position: absolute;
      left: 74px;
      top: 50px;

      .unitInfoLabel {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        padding: 0px 5px 0px 20px;
        color: #000000;
        text-transform: uppercase;
        display: inline-block;
      }

      .unitInfo {
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        text-transform: uppercase;
        font-weight: bold;
        display: inline-block;
      }
    }

    .volunteersContainer {
      position: absolute;
      left: 95px;
      top: 120px;
      font-size: 16px;
      color: #000000;
      width: 80%;

    }

    .date {
      position: absolute;
      left: 210px;
      top: 912px;
      font-size: 16px;
      line-height: 30px;
      color: #000;

    }

    .podpis {
      width: 300px;
      position: absolute;
      margin-top: -70px;
      margin-left: 100px;
      z-index: 2;
    }

    .zig {
      width: 200px;
      position: absolute;
      margin-top: -10px;
      margin-left: 180px;
      z-index: 1;
    }
  }
}
</style>
